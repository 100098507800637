<template>
  <b-row class="init-booking-wrapper justify-content-center m-0">
    <b-col md="4">
      <b-card class="card-custom user-select-none my-10">
        <template #default>
          <div class="text-center fs-xxxl font-weight-bolder">ORBI CITY</div>
          <div v-if="!formSubmitted">
            <div class="text-center fs-xxl font-weight-bolder text-uppercase mt-3">{{ $t('title.every_stay_story') }}</div>
            <div class="text-center fs-md mt-2">{{ $t('description.enter_email_to_start_reservation') }}</div>

            <floating-input-form-group
              class="mt-10 mb-7"
              :value="form.email"
              :placeholder="emailPlaceholder"
              :error="error.email"
              @input="form.email = $event.trim()"
            />

            <button
              class="btn btn-green fs-xl font-weight-bold font-size-3 text-uppercase w-100 mb-5 mt-3 px-9 py-4"
              @click="submit"
            >
              {{ $t('btn.submit') }}
            </button>
          </div>

          <div v-else>
            <div class="text-center h6 font-weight-bolder text-uppercase mt-3">{{ $t('title.booking_details_on_the_way') }}</div>
            <div class="text-center font-weight-bolder text-uppercase mt-2">{{ $t('description.check_email_for_GB_instructions') }}</div>

            <button class="btn btn-green fs-xl font-weight-bold font-size-3 text-uppercase w-100 mb-5 mt-3 px-9 py-4" @click="onOk">
              {{ $t('btn.ok') }}
            </button>
          </div>
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { formValidation } from '@/mixins/formValidation';
import FloatingInputFormGroup from "@/components/elements/form-groups/FloatingInputFormGroup.vue";

export default {
  components: {
    FloatingInputFormGroup
  },
  mixins: [formValidation],
  data: () => ({
    formSubmitted: false,
    form: {
      email: ''
    },
    error: {
      email: ''
    }
  }),
  computed: {
    apartmentId() {
      return this.$route.params.apartmentId;
    },
    hash() {
      return this.$route.params.hash;
    },
    emailPlaceholder() {
      return this.form.email ? this.$t('label.email_address') : this.$t('placeholder.enter_email');
    }
  },
  methods: {
    validateForm() {
      this.error.email = this.validateEmail(this.form.email);

      return !this.error.email;
    },
    async submit() {
      if (!this.validateForm()) return false;
      if (!this.apartmentId || !this.hash) return false;

      try {
        await this.$store.dispatch('guestBookingStore/SEND_EMAIL', {
          email: this.form.email,
          apartmentId: this.apartmentId,
          hash: this.hash,
        });
        this.formSubmitted = true;
      } catch (error) {
        console.log('error');
        console.log({error});
      }
    },
    onOk() {
      window.location.href = 'https://orbihotels.com/';
    }
  }
}
</script>

<style lang="scss" scoped>
.init-booking-wrapper::v-deep {
	.active {
		font-size: 14px !important;
	}
}

.card-custom {
  border-radius: 20px;
}
</style>